import React from 'react';
import './OurTeamComponent.css';
import { Col, Container, Row } from 'react-bootstrap';
import outTeamPicture from '../../../content/aboutus/aboutus_circle_1.svg';
import { useNavigate } from 'react-router-dom';


const OurTeamComponent: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container className='our-team-styled-text'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
            <Row>
                <h5 className="main-color our-team-text-margin">Our Team</h5>
            </Row>
            <Row>
                <Col><h2 className='our-team-text-margin'>Meet with <span className="main-color">Our Team</span></h2></Col>
            </Row>
            <Row className='our-team-text-margin' style={{ marginTop: '20px' }}>
                <p>
                    Behind Introductable is a dedicated team of professionals passionate about revolutionizing the way businesses network and how people network.
                    From our experienced developers to our customer focused support staff, every member of the Introductable team is committed to helping you succeed.
                </p>
            </Row>
            <Row style={{ marginTop: '15px' }} onClick={() => {navigate('/blog/meet-founders')}}>
                <Col xs={12} md={6} className='our-team-image-position'>
                    <img src={outTeamPicture} className='our-team-image' alt="ourTeamPicture" />
                </Col>
                <Col xs={12} md={6} style={{ marginTop: '25px' }}>
                    <Row className='our-team-text-position'>
                        <p>Co-Founders</p>
                    </Row>
                    <Row className='our-team-text-position'>
                        <h2 className="main-color">Jeff Buechler</h2>
                    </Row>
                    <Row className='our-team-text-position'>
                        <h2 className="main-color">Przemyslaw Roguski</h2>
                    </Row>
                </Col>
            </Row>
            </Row>
        </Container>
    );
    }

export default OurTeamComponent;
